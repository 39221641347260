<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="id" prop="id" width="50"></el-table-column>
      <el-table-column label="套餐名称" prop="name"></el-table-column>
    </el-table>
    
    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>


<script>
import { fetchUnicomPackageList } from "@/api/active";

export default {
  data() {
    return {
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        phone: "",
        packageId: "",
        startTime: "",
        endTime: "",
      },
      total: 0,
    };
  },

  methods: {
    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchUnicomPackageList({ ...this.query, ...this.pages }).then(
        (res) => {
          this.total = res.data.data.total;
          this.dataSource = res.data.data;
        }
      );
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
