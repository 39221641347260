import request from '@/common/axios';

// 查询联通活动登记列表
export function fetchUnicomRegList(data) {
  return request({
    url: '/uja/preApplyPageList',
    method: 'POST',
    data: {
        ...data
    }
  });
}

// 修改备注
export function updateUnicomRegRemark(data) {
  return request({
    url: '/uja/updatePreApplyRecord',
    method: 'POST',
    data: {
        ...data
    }
  });
}

// 发放联通活动奖励
export function sendAward(data) {
  return request({
    url: '/uja/giveMemberAwards',
    method: 'POST',
    data: {
        ...data
    }
  });
}

// 套餐管理
export function fetchUnicomPackageList(data) {
  return request({
    url: '/uja/packageList',
    method: 'POST',
    data: {
        ...data
    }
  });
}
